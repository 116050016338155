import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { Header } from './header'
import { Navigation } from './navigation'
import { VerificationInfo } from './verificationInfo'
import Notifications from '../Notifications/Notifications'
import s from './layout.module.scss'

const Layout = () => {
  const [getSellerInfo] = useGetSellerInfoMutation()

  useEffect(() => void getSellerInfo(), [])

  return (
    <div className={s.layout}>
      <Header />
      <main className={s.main}>
        <div className={s['nav-wrapper']}>
          <Navigation />
          <div className={s.verification}>
            <VerificationInfo />
          </div>
        </div>
        <div className={s['content-wrapper']}>
          <Outlet />
        </div>
      </main>
      <Notifications />
    </div>
  )
}

export default Layout
