import { routes } from 'constants/routes'
import { MenuItem } from './models'

export const menuList: MenuItem[] = [
  { title: 'Статистика', href: '/', icon: 'statistics' },
  { title: 'Заказы', href: routes.ORDERS, icon: 'document' },
  { title: 'Товары', href: routes.PRODUCTS, icon: 'item' },
  { title: 'Партнерская программа', href: routes.PARTNERS, icon: 'partnership' },
  { title: 'Отзывы', href: routes.REVIEWS, icon: 'like' },
  { title: 'Сообщения', href: routes.CHATS, icon: 'message' },
  { title: 'Настройки', href: routes.SETTINGS, icon: 'settings' }
]
