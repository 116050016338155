import React, { FC, useRef, useState } from 'react'
import cx from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'redux/hook'
import { setActiveSellerShop } from 'redux/seller/sellerSlice'
import { useGetSellerInfoMutation } from 'redux/seller/sellerApi'
import { TSellerShop } from 'models'
import { ProductImage } from 'components/product'
import { useMessage, useOnClickOutside } from 'hooks'
import { InitRegistration } from 'components/registration'
import { Icon, Modal, Text } from 'components/UI'
import { MenuNavLink } from './MenuNavLink'
import { MenuItem } from './models'
import { menuList } from './const'
import s from './navigation.module.scss'

const Navigation: FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false)
  const [showInitRegistrationModal, setShowInitRegistrationModal] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const shopInfoRef = useRef(null)
  const { onFocusElement } = useOnClickOutside(
    shopInfoRef,
    () => setIsOpenMenu(!isOpenMenu),
    () => setIsOpenMenu(false)
  )

  const [getSellerInfo] = useGetSellerInfoMutation()
  const { data, activeShop } = useAppSelector(({ store }) => store.seller)

  const handlerSelectShop = async (shop: TSellerShop) => {
    if (activeShop?._id !== shop._id) {
      setIsOpenMenu(!isOpenMenu)
      dispatch(setActiveSellerShop(shop))
      await getSellerInfo()
      navigate('/')
    }
  }

  const handleShowModal = () => setShowInitRegistrationModal(true)
  const handleHideModal = () => setShowInitRegistrationModal(false)

  const onCreateShop = () => {
    handleShowModal()
    setIsOpenMenu(false)
  }

  const onSuccessInitRegistration = () => {
    handleHideModal()
    useMessage('Вы добавили новый магазин')
  }

  return (
    <div className={s.navigation}>
      <div className={s['navigation__shop-info']} ref={shopInfoRef}>
        <div className={s['select-shop']} onClick={onFocusElement}>
          <ProductImage className={s['select-shop__avatar']} src={activeShop?.image} border={false} />
          <div className={s['select-shop__name']}>
            <Text size='md' overflow='ellipsis'>
              {activeShop?.name ?? 'Неизвестно'}
            </Text>
          </div>
          <Icon className={s['select-shop__icon']} name='arrow-down' color='middle-grey' />
        </div>
        {isOpenMenu && (
          <div className={s['navigation__shops-popup']}>
            {data?.shops &&
              data.shops.map((shop: TSellerShop) => (
                <div
                  key={shop._id}
                  className={cx(s['popup-item'], { [s.active]: activeShop?._id === shop._id })}
                  onClick={() => handlerSelectShop(shop)}
                >
                  <div className={s['popup-item__content']}>
                    <ProductImage className={s.img} src={shop?.image} border={false} />
                    <Text size='md'>{shop.name}</Text>
                  </div>
                </div>
              ))}
            <div onClick={onCreateShop} className={cx(s['popup-item'], 'offset-top-16')}>
              <div className={s['popup-item__content']}>
                <Icon name='plus' color='dark-grey' />
                <Text size='md' color='dark-grey'>
                  Добавить магазин
                </Text>
              </div>
            </div>
          </div>
        )}
      </div>
      <ul className={s['navigation__menu-links']}>
        {menuList.map((v: MenuItem) => (
          <MenuNavLink key={v.href} menuLink={v} />
        ))}
      </ul>
      <Modal isOpen={showInitRegistrationModal} onRequestClose={handleHideModal}>
        <div className={s['init-registration-modal']}>
          <InitRegistration onButtonBackClick={handleHideModal} onSuccess={onSuccessInitRegistration} />
        </div>
      </Modal>
    </div>
  )
}

export default Navigation
